.fs {
  width: 100%;
  height: 100%;
  background: #2f353a;
}

.spinner {
  width: 25px;
  height: 25px;
}

.error_msg {
  justify-content: center;
  display: flex;
  margin: 5px;
  color: rgb(247, 34, 34);
  font-weight: 500;
}

.fade {
  animation: fade-in-keyframes 0.5s linear;
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
    height: 0em;
  }

  to {
    opacity: 1;
    height: 1em;
  }
}

.closeWidth {
  width: 0px;
  min-width: 0px;
  animation: fade-to0-width 0.3s ease;
}

@keyframes fade-to0-width {
  from {
    min-width: 150px;
  }

  to {
    min-width: 0px;
  }
}

.openWidth {
  width: auto;
  min-width: 150px;
  animation: fade-to200-width 0.3s ease;
}

@keyframes fade-to200-width {
  from {
    min-width: 0px;
  }

  to {
    min-width: 150px;
  }
}

.f {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-image: url("./bg.jpg");
  position: absolute !important;
}

@media screen and (min-width: 500px) {
  .login_box {
    display: flex;
    flex-direction: column;
    z-index: 100;
    min-width: 500px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 0px 7px 2px #b7b7bb;
    background-color: rgba(218, 218, 218, 0.78);
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    color: hsl(206, 59%, 42%);
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 499px) {
  .login_box {
    min-width: 330px;
    width: 90%;
    display: flex;
    flex-direction: column;
    z-index: 100;
    background-color: rgba(218, 218, 218, 0.78);
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 0px 7px 2px #b7b7bb;
  }

  .title {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    color: hsl(206, 59%, 42%);
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: center;
  }
}

.login_fields {
  margin-top: 10px;
}

#logo {
  width: 90%;
  max-width: 500px;
}

.fieldname {
  font-weight: 600;
  padding-right: 20px;
}

.login_input {
  border-radius: 8px;
  border-width: 1px;
  border-style: inset;
  background: rgb(246, 255, 255);
  padding-top: 5px;
  padding-bottom: 3px;
  padding-left: 10px;
  font-family: "Lucida Console", Monaco, monospace;
}

.field_line {
  padding: 3px;
  justify-content: space-between;
  display: flex;
}

.login_button {
  flex: 1 1;
  padding: 8px;
  margin: 6px;
  border-radius: 10px;
  font-size: 1em;
  margin-top: 10px;
  border-style: outset;
  background: #baea94;
  font-weight: 600;
  border-width: 2px;
  color: rgb(1, 25, 41);
  font-family: "IBM Plex Sans", sans-serif;
}

.title {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  color: hsl(206, 59%, 42%);
  margin-bottom: 10px;
}